import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";

import { useEffect, MouseEvent } from "react";
import { useState } from "react";

import { Amplify, Auth, Hub } from "aws-amplify";
import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";

const UserLogin = () => {
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userName, setUserName] = useState<string>("");

  const menuOpen = Boolean(anchorEl);

  const handleLoginOpen = () => {
    toSignIn();
    setLoginOpen(true);
  };
  const handleLoginClose = () => {
    setLoginOpen(false);
  };

  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { route } = useAuthenticator((context) => [context.route]);
  const { toSignIn } = useAuthenticator((context) => [context.toSignIn]);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signIn":
        case "signUp":
          setLoginOpen(false);
          break;
        case "signOut":
          window.location.reload();
          break
      }

      return unsubscribe;
    });
  }, []);

  useEffect(() => {
    const getUserName = async () => {
      if (user) {
        const userInfo = await Auth.currentAuthenticatedUser();

        setUserName(
          userInfo.attributes.given_name + " " + userInfo.attributes.family_name
        );
      } else {
        setUserName("");
      }
    };
    getUserName();
  }, [user]);

  return (
    <>
      {(() => {
        switch (route) {
          case "authenticated":
            return (
              <>
                <IconButton
                  onClick={handleMenuOpen}
                  sx={{ p: 0 }}
                  id="user-icon"
                >
                  <Avatar />
                </IconButton>
                <Menu
                  open={Boolean(anchorEl)}
                  onClick={handleMenuClose}
                  anchorEl={anchorEl}
                >
                  <MenuItem>
                    Signed in as&nbsp;<b>{userName}</b>
                  </MenuItem>
                  <MenuItem onClick={signOut}>Sign out</MenuItem>
                </Menu>
              </>
            );
            break;
          case "idle":
            return <CircularProgress color="secondary" />;
            break;
          default:
            return (
              <Button
                color="secondary"
                variant="contained"
                onClick={handleLoginOpen}
              >
                Login
              </Button>
            );
            break;
        }
      })()}

      <Modal open={loginOpen} onClose={handleLoginClose}>
        <Box
          sx={{
            position: "absolute",
            left: "0px",
            right: "0px",
            top: "0px",
            bottom: "0px",
            margin: { xs: "0px", sm: "auto" },
            width: { xs: "100%", sm: "fit-content" },
            height: "fit-content",
          }}
        >
          <Authenticator
            socialProviders={[/*"amazon", "google"*/]}
            variation="default"
            loginMechanisms={["email"]}
            signUpAttributes={["given_name", "family_name"]}
            formFields={{
              signUp: {
                email: { order: 1 },
                given_name: { label: "First Name", order: 2 },
                family_name: { label: "Last Name", order: 3 },
              },
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default UserLogin;
