import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Amplify } from "aws-amplify";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    mandatorySignIn: false,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${window.location.protocol}//${window.location.host}`,
      redirectSignOut: `${window.location.protocol}//${window.location.host}`,
      clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      responseType: "code",
    },
  },
  // API: {
  //   graphql_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT + "/graphql",
  //   authentication_type: 'AMAZON_COGNITO_USER_POOLS',
  // },
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_GRAPHQL_ENDPOINT + "/graphql",
  //aws_appsync_authenticationType: "AWS_IAM",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
