import { Box, Skeleton, Typography } from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { useCallback, useState } from "react";

import { Tweet } from "react-twitter-widgets";

const TweetBox = ({ id, onLoad }: { id: string; onLoad?: () => void }) => {
  const [tweetLoaded, setTweetLoaded] = useState(false);
  return (
    <>
      <Tweet
        onLoad={() => {
          onLoad?.();
          setTweetLoaded(true);
        }}
        tweetId={id}
      />

      {tweetLoaded ?? (
        <Skeleton variant="rectangular" height={200} sx={{ mb: 2 }} />
      )}
    </>
  );
};

const TwitterFeed = () => {
  const [tweetsLoaded, setTweetsLoaded] = useState(false);
  const [tweetsLoading, setTweetsLoading] = useState(false);
  const [tweetIDs, setTweetIDs] = useState<string[]>([]);

  const loadTweets = useCallback(async () => {
    setTweetsLoading(true);
    const result: any = await API.graphql(
      graphqlOperation(
        `query ListTweets($limit: Int) {
      listTweets(limit: $limit) {
        tweets {
          id
        }
      }
    }`,
        {
          limit: 4,
        }
      )
    );

    setTweetIDs(result.data.listTweets.tweets.map((tweet: any) => tweet.id));
    setTweetsLoading(false);
  }, []);

  if (!tweetsLoaded) {
    loadTweets();
    setTweetsLoaded(true);
  }

  return (
    <Box
      // display={{ xs: "block", lg: "none" }}
      component="aside"
      sx={{ mt: 0, pr: 0 }}
    >
      <Typography
        variant="h6"
        mt={2}
        mb={2}
        sx={{
          pt: { xs: 2, lg: 0 },
          borderTop: { xs: "1px solid", md: "none" },
          borderTopColor: { xs: "primary.main" },
        }}
      >
        Tweets by{" "}
        <a
          href="https://twitter.com/CantaloupeNews"
          target="_blank"
          rel="noreferrer"
        >
          @CantaloupeNews
        </a>
      </Typography>
      {tweetsLoading &&
        [0, 1, 2].map((key) => (
          <Skeleton
            key={key}
            variant="rectangular"
            height={200}
            sx={{ mb: 2 }}
          />
        ))}
      {tweetIDs.map((id) => (
        // <Tweet onLoad={() => setTweetsLoading(false)} tweetId={id} key={id} />
        <TweetBox onLoad={() => setTweetsLoading(false)} id={id} key={id} />
      ))}

      {/* <Tweet tweetId="1681367254368243713" />
      <Tweet tweetId="1681296948383584258" />
      <Tweet tweetId="1681288217251774465" /> */}
    </Box>
  );
};

export default TwitterFeed;
