import MerchLink1 from "../images/merch/merch-womens-tee.jpg";
import MerchLink2 from "../images/merch/merch-christie-mens-tee.jpg";
import MerchLink3 from "../images/merch/merch-mens-tee.jpg";

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

const MerchLinks = () => {
  return (
    <>
      <Typography gutterBottom variant="h6" component="div">
        Get your Cantaloupe News merch now!
      </Typography>

      <Card sx={{ mb: 2 }}>
        <CardActionArea
          href="https://cantaloupenews.creator-spring.com/listing/chris-christie-i-m-not-fat?product=46"
          target="_blank"
        >
          <CardMedia src={MerchLink2} component="img" alt="Chris Christie &quot;I'm not fat&quot; t-shirt" />
        </CardActionArea>
      </Card>

      <Card sx={{ mb: 2 }}>
        <CardActionArea
          href="https://cantaloupenews.creator-spring.com/"
          target="_blank"
        >
          <CardMedia src={MerchLink1} component="img" alt="Merch Links" />
        </CardActionArea>
      </Card>

      <Card sx={{ mb: 2 }}>
        <CardActionArea
          href="https://cantaloupenews.creator-spring.com/"
          target="_blank"
        >
          <CardMedia src={MerchLink3} component="img" alt="Merch links" />
        </CardActionArea>
      </Card>
    </>
  );
};

export default MerchLinks;
