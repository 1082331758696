/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDraftById = /* GraphQL */ `
  query GetDraftById($id: String!) {
    getDraftById(id: $id) {
      author
      body
      bodyJSON
      date
      description
      id
      image {
        caption
        credit
        src
      }
      isPublic
      title
      user
    }
  }
`;
export const getStoryById = /* GraphQL */ `
  query GetStoryById($id: String!) {
    getStoryById(id: $id) {
      author
      body
      date
      deleted
      description
      draft {
        body
        date
        image {
          caption
          credit
          src
        }
        title
      }
      id
      image {
        caption
        credit
        src
      }
      title
    }
  }
`;
export const listAllDrafts = /* GraphQL */ `
  query ListAllDrafts {
    listAllDrafts {
      author
      body
      bodyJSON
      date
      description
      id
      image {
        caption
        credit
        src
      }
      isPublic
      title
      user
    }
  }
`;
export const listMyDrafts = /* GraphQL */ `
  query ListMyDrafts($limit: Int, $nextToken: String) {
    listMyDrafts(limit: $limit, nextToken: $nextToken) {
      drafts {
        author
        body
        bodyJSON
        date
        description
        id
        image {
          caption
          credit
          src
        }
        isPublic
        title
        user
      }
      nextToken
    }
  }
`;
export const listStories = /* GraphQL */ `
  query ListStories($limit: Int, $nextToken: String, $status: StoryStatus) {
    listStories(limit: $limit, nextToken: $nextToken, status: $status) {
      nextToken
      stories {
        author
        body
        date
        deleted
        description
        draft {
          body
          date
          title
        }
        id
        image {
          caption
          credit
          src
        }
        title
      }
    }
  }
`;
export const listTweets = /* GraphQL */ `
  query ListTweets($limit: Int, $nextToken: String) {
    listTweets(limit: $limit, nextToken: $nextToken) {
      nextToken
      tweets {
        date
        id
      }
    }
  }
`;
