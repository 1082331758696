/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addTweet = /* GraphQL */ `
  mutation AddTweet($id: ID!) {
    addTweet(id: $id) {
      date
      id
    }
  }
`;
export const createDraft = /* GraphQL */ `
  mutation CreateDraft($draft: DraftInput!) {
    createDraft(draft: $draft) {
      author
      body
      bodyJSON
      date
      description
      id
      image {
        caption
        credit
        src
      }
      isPublic
      title
      user
    }
  }
`;
export const createStory = /* GraphQL */ `
  mutation CreateStory($story: StoryInput) {
    createStory(story: $story) {
      author
      body
      date
      deleted
      description
      draft {
        body
        date
        image {
          caption
          credit
          src
        }
        title
      }
      id
      image {
        caption
        credit
        src
      }
      title
    }
  }
`;
export const deleteDraft = /* GraphQL */ `
  mutation DeleteDraft($id: String!) {
    deleteDraft(id: $id)
  }
`;
export const deleteStory = /* GraphQL */ `
  mutation DeleteStory($id: String!) {
    deleteStory(id: $id)
  }
`;
export const deleteTweet = /* GraphQL */ `
  mutation DeleteTweet($id: ID!) {
    deleteTweet(id: $id)
  }
`;
export const generateImageUploadSignedURL = /* GraphQL */ `
  mutation GenerateImageUploadSignedURL(
    $storyId: String!
    $storyType: StoryType
  ) {
    generateImageUploadSignedURL(storyId: $storyId, storyType: $storyType)
  }
`;
export const publishDraft = /* GraphQL */ `
  mutation PublishDraft($date: AWSDateTime, $id: String!) {
    publishDraft(date: $date, id: $id) {
      author
      body
      date
      deleted
      description
      draft {
        body
        date
        image {
          caption
          credit
          src
        }
        title
      }
      id
      image {
        caption
        credit
        src
      }
      title
    }
  }
`;
export const publishStoryDraft = /* GraphQL */ `
  mutation PublishStoryDraft($id: String!) {
    publishStoryDraft(id: $id) {
      author
      body
      date
      deleted
      description
      draft {
        body
        date
        image {
          caption
          credit
          src
        }
        title
      }
      id
      image {
        caption
        credit
        src
      }
      title
    }
  }
`;
export const sendContactFormMessage = /* GraphQL */ `
  mutation SendContactFormMessage(
    $challengeResponse: ContactFormChallengeResponse
  ) {
    sendContactFormMessage(challengeResponse: $challengeResponse) {
      code
      message
      status
    }
  }
`;
export const submitContactFormMessage = /* GraphQL */ `
  mutation SubmitContactFormMessage($message: ContactFormMessage) {
    submitContactFormMessage(message: $message) {
      code
      difficulty
      hash
      message
      status
    }
  }
`;
export const updateDraft = /* GraphQL */ `
  mutation UpdateDraft($draft: DraftInput, $id: String!) {
    updateDraft(draft: $draft, id: $id) {
      author
      body
      bodyJSON
      date
      description
      id
      image {
        caption
        credit
        src
      }
      isPublic
      title
      user
    }
  }
`;
export const updateStory = /* GraphQL */ `
  mutation UpdateStory($story: StoryInput) {
    updateStory(story: $story) {
      author
      body
      date
      deleted
      description
      draft {
        body
        date
        image {
          caption
          credit
          src
        }
        title
      }
      id
      image {
        caption
        credit
        src
      }
      title
    }
  }
`;
export const updateStoryDraft = /* GraphQL */ `
  mutation UpdateStoryDraft($draft: StoryDraftInput, $id: String!) {
    updateStoryDraft(draft: $draft, id: $id) {
      author
      body
      date
      deleted
      description
      draft {
        body
        date
        image {
          caption
          credit
          src
        }
        title
      }
      id
      image {
        caption
        credit
        src
      }
      title
    }
  }
`;
