import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { API, Auth } from "aws-amplify";
import { AwsRum, AwsRumConfig } from "aws-rum-web";

import "./App.css";
import React from "react";

import { ReactComponent as Logo } from "./images/alt-logo.svg";
import MainLayout from "./layout/MainLayout";
import { HelmetProvider } from "react-helmet-async";
import { Box, ThemeProvider, createTheme, useTheme } from "@mui/material";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfService from "pages/TermsOfService";
import ContactUs from "pages/ContactUs";
import Error404 from "pages/Error404";

let allowCookies = true;
try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    identityPoolId: process.env.REACT_APP_RUM_IDENTITY_POOL_ID,
    guestRoleArn: process.env.REACT_APP_RUM_APP_UNAUTH_ROLE_ARN,
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: [],
    allowCookies,
    enableXRay: false,
    disableAutoPageView: true,
  };
  const APPLICATION_ID: string =
    (window.location.hostname === "localhost"
      ? process.env.REACT_APP_RUM_APP_ID_LOCALHOST
      : process.env.REACT_APP_RUM_APP_ID) ?? "";
  const APPLICATION_VERSION: string = "1.0.0";
  const APPLICATION_REGION: string = "us-east-1";

  window.awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (e: any) {
  console.log(e.message);
}

const Home = React.lazy(() => import("./pages/Home"));
const About = React.lazy(() => import("./pages/About"));
const Admin = React.lazy(() => import("./pages/Admin"));
const AdminStories = React.lazy(() => import("./pages/Admin/AdminStories"));
const AdminTweets = React.lazy(() => import("./pages/Admin/AdminTweets"));
const Story = React.lazy(() => import("./pages/Story"));

export const SiteContext = React.createContext<any>({});

const CDN_DOMAIN = process.env.REACT_APP_CDN_DOMAIN ?? "";

const theme = createTheme({
  palette: {
    primary: {
      light: "#fe9a53",
      main: "#fe8128",
      dark: "#b15a1c",
      contrastText: "#fff",
    },
    secondary: {
      light: "#8ca273",
      main: "#708b50",
      dark: "#4e6138",
      contrastText: "#000",
    },
  },
});

const Loader = () => (
  <Box
    sx={{
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: "primary.main",
    }}
  >
    <Logo
      className="pulse"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: "50%",
        height: "50%",
        margin: "auto",
        display: "block}",
      }}
    />
  </Box>
);

const AppInner = () => {};

const App = () => {
  const [cdnDomain, setCdnDomain] = React.useState<string>(CDN_DOMAIN);

  return (
    <ThemeProvider theme={theme}>
      <Authenticator.Provider>
        <SiteContext.Provider value={{ cdnDomain: CDN_DOMAIN /*, awsRum*/ }}>
          <HelmetProvider>
            <BrowserRouter>
              <Routes>
                <Route element={<MainLayout />}>
                  <Route
                    index
                    element={
                      <React.Suspense fallback={<Loader />}>
                        <Home />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/story/:dateId/:storyId"
                    element={
                      <React.Suspense fallback={<Loader />}>
                        <Story />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/about"
                    element={
                      <React.Suspense fallback={<Loader />}>
                        <About />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/contact-us"
                    element={
                      <React.Suspense fallback={<Loader />}>
                        <ContactUs />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/admin"
                    element={
                      <React.Suspense fallback={<Loader />}>
                        <Admin />
                      </React.Suspense>
                    }
                  >
                    <Route path="users" element={<div>Users</div>} />
                    <Route
                      path="stories"
                      element={
                        <React.Suspense fallback={<Loader />}>
                          <AdminStories />
                        </React.Suspense>
                      }
                    />
                    <Route
                      path="tweets"
                      element={
                        <React.Suspense fallback={<Loader />}>
                          <AdminTweets />
                        </React.Suspense>
                      }
                    />
                  </Route>
                  <Route
                    path="/privacy-policy"
                    element={
                      <React.Suspense fallback={<Loader />}>
                        <PrivacyPolicy />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/terms-of-service"
                    element={
                      <React.Suspense fallback={<Loader />}>
                        <TermsOfService />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <React.Suspense fallback={<Loader />}>
                        <Error404 />
                      </React.Suspense>
                    }
                  />
                </Route>

                {/* <Route path="/user-auth" element={<UserAuth />} /> */}
              </Routes>
            </BrowserRouter>
          </HelmetProvider>
        </SiteContext.Provider>
      </Authenticator.Provider>
    </ThemeProvider>
  );
};

export default App;
