import { Box, Typography, useTheme, lighten } from "@mui/material";
import { ReactNode } from "react";

interface StoryBodyProps {
  body: any;
}

interface BodyNodeProps {
  node: any;
}

const BodyNode = ({ node }: BodyNodeProps) => {
  const theme = useTheme();
  if(!node)
    return null;
  if (node.type === "text") {
    const style: any = {};
    if (node?.marks?.length > 0) {
      node.marks.forEach((mark: any) => {
        switch (mark.type) {
          case "bold":
            style["fontWeight"] = "bold";
            break;
          case "italic":
            style["fontStyle"] = "italic";
            break;
          case "underline":
            style["textDecoration"] = "underline";
            break;
          case "strike":
            style["textDecoration"] = `line-through ${
              style["textDecoration"] || ""
            }`;
            break;
          case "code":
            style["fontFamily"] = "monospace";
            style["backgroundColor"] = "#eee";
            style["padding"] = "2px 4px";
        }
      });
    }
    return <span style={style}>{node.text}</span>;
  }

  let child: ReactNode;

  if (node.content) {
    child = (
      <>
        {node.content.map((item: any, index: number) => {
          item.parent = node;
          return <BodyNode key={index} node={item} />;
        })}
      </>
    );
  } else {
    switch (node.type) {
      case "text":
        child = node.text;
    }
    child = <></>;
  }

  switch (node.type) {
    case "doc":
      return child;
    case "paragraph":
      return (
        <Typography
          variant="body1"
          paragraph={true}
          sx={{ mt: node.parent.type === "doc" ? 4 : 0, lineHeight: "2rem", fontSize: "1.2rem", mx: 2  }}
        >
          {child}
        </Typography>
      );
    case "heading":
      return (
        <Typography variant="h5" component="h3">
          {child}
        </Typography>
      );
    case "horizontalRule":
      return <hr />;
    case "bulletList":
      return <ul>{child}</ul>;
    case "orderedList":
      return <ol>{child}</ol>;
    case "listItem":
      return (
        <Typography variant="body1" component="li" sx={{ mb: 1 }}>
          {child}
        </Typography>
      );
    case "blockquote":
      return (
        <Typography
          component="blockquote"
          sx={{
            fontStyle: "italic",
            marginLeft: 0,
            borderLeft: "3px solid #ccc",
            color: "#888",
            my: 4,
          }}
        >
          {child}
        </Typography>
      );
    case "codeBlock":
      return (
        <pre
          style={{
            backgroundColor: "#2b2b2b",
            color: "#f8f8f2",
            padding: "1rem",
            overflow: "auto",
            maxHeight: 200,
            borderRadius: 3,
          }}
        >
          <code>{child}</code>
        </pre>
      );
    case "callout":
      let darkColor, lightColor;
      switch (node.attrs.type) {
        case "warning":
          darkColor = theme.palette.warning.dark;
          lightColor = lighten(theme.palette.warning.light, 0.85);
          break;
        case "error":
          darkColor = theme.palette.error.dark;
          lightColor = lighten(theme.palette.error.light, 0.85);
          break;
        case "success":
          darkColor = theme.palette.success.dark;
          lightColor = lighten(theme.palette.success.light, 0.85);
          break;
        default:
          darkColor = theme.palette.info.dark;
          lightColor = lighten(theme.palette.info.light, 0.85);
      }
      return (
        <Typography
          component="div"
          variant="body1"
          sx={{
            borderLeftStyle: "solid",
            borderColor: darkColor,
            borderLeftWidth: 2,
            backgroundColor: lightColor,
            padding: 2,
            mb: 4,
          }}
        >
          {child}
        </Typography>
      );
  }

  return <></>;
};

const StoryBody = ({ body }: StoryBodyProps) => {
  return (
    <Box>
      <BodyNode node={body} />
    </Box>
  );
};

export default StoryBody;
