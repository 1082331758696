import { Box, Container } from "@mui/material";
import { PropsWithChildren } from "react";

const LayoutGrid = ({
  LeftSide,
  RightSide,
  children,
}: PropsWithChildren<{
  LeftSide?: JSX.Element | undefined | null;
  RightSide?: JSX.Element | undefined | null;
}>) => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "grid",
        gap: 4,
        gridTemplateAreas: {
          xs: "'main' 'right'",
          md: "'main main main right' 'main main main left'",
          lg: "'left main main right'",
          xl: "'left main main main right'",
        },
        gridTemplateColumns: {
          xs: null,
          md: "repeat(3, 1fr) 300px",
          lg: "300px repeat(2, 1fr) 300px",
          xl: "300px repeat(3, 1fr) 300px",
        },
        gridTemplateRows: "auto 1fr"
        // mx: 2,
      }}
    >
      <Box
        gridArea="left"
        sx={
          {
            display: { xs: "none", md: "block" }
          }
        }
      >
        {LeftSide}
      </Box>
      <Box gridArea="main">{children}</Box>
      <Box gridArea="right">
        {RightSide}
      </Box>
    </Container>
  );
};

export default LayoutGrid;
