import { Box, Typography } from "@mui/material";
import TwitterFeed from "components/TwitterFeed";
import { Helmet } from "react-helmet-async";
import { SideBar } from "./Story";
import MerchLinks from "components/MerchLinks";
import LayoutGrid from "layout/LayoutGrid";

const LeftSide = () => {
  return (
    <Box mt={3.5}>
      <TwitterFeed />
    </Box>
  );
};

const RightSide = () => {
  return (
    <Box mt={3.5}>
      <SideBar />
      <MerchLinks />
    </Box>
  );
};

const TermsOfService = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Service | Cantaloupe News</title>
      </Helmet>
      <LayoutGrid LeftSide={<LeftSide />} RightSide={<RightSide />}>
        <Typography variant="h3" component="h1" mt={2}>
          Terms of Service
        </Typography>
        <Typography variant="body2" component="p" gutterBottom ml={1}>
          Effective date: <time dateTime="2023-06-28">June 28, 2023</time>
        </Typography>
        <Typography variant="body1" component="p" gutterBottom mt={2}>
          Welcome to Cantaloupe News! These Terms of Service ("Terms") govern
          your access to and use of the Cantaloupe News website
          (cantaloupenews.com) and any related services, features, or content
          (collectively, the "Services"). By accessing or using our Services,
          you agree to be bound by these Terms. If you do not agree to these
          Terms, please refrain from using our Services.
        </Typography>

        <ol>
          <Typography variant="h5" component="li" mt={2} mb={1}>
            Acceptance of Terms
            <Typography variant="body1" component="p" gutterBottom>
              By using our Services, you affirm that you are at least 13 years
              old and capable of entering into a legal agreement. If you are
              using our Services on behalf of an organization or entity, you
              represent and warrant that you have the authority to bind that
              organization or entity to these Terms.
            </Typography>
          </Typography>

          <Typography variant="h5" component="li" mt={2} mb={1}>
            Content and Intellectual Property Rights
            <Typography variant="body1" component="p" gutterBottom>
              2.1 Content Ownership: All content provided through our Services,
              including articles, text, graphics, images, videos, and other
              materials, are the property of Cantaloupe News or its content
              providers. You acknowledge and agree that you do not acquire any
              ownership rights or licenses to the content.
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              2.2 User-generated Content: You may have the opportunity to
              contribute user-generated content, such as comments or replies to
              articles. By submitting user-generated content, you grant
              Cantaloupe News a worldwide, non-exclusive, royalty-free,
              perpetual, irrevocable, and sublicensable right to use, reproduce,
              modify, adapt, publish, translate, distribute, display, and
              perform the content in any media.
            </Typography>
          </Typography>

          <Typography variant="h5" component="li" mt={2} mb={1}>
            Prohibited Conduct
            <Typography variant="body1" component="p" gutterBottom>
              When using our Services, you agree not to:
            </Typography>
            <ul>
              <Typography variant="body1" component="li" gutterBottom>
                Violate any applicable laws or regulations.
              </Typography>
              <Typography variant="body1" component="li" gutterBottom>
                Infringe upon the intellectual property rights of others.
              </Typography>
              <Typography variant="body1" component="li" gutterBottom>
                Post, upload, or transmit any content that is unlawful, harmful,
                defamatory, obscene, or otherwise objectionable.
              </Typography>
              <Typography variant="body1" component="li" gutterBottom>
                Engage in any conduct that could disrupt or interfere with the
                functioning of our Services or compromise the security or
                integrity of our systems.
              </Typography>
              <Typography variant="body1" component="li" gutterBottom>
                Impersonate any person or entity or falsely state or otherwise
                misrepresent your affiliation with a person or entity.
              </Typography>
              <Typography variant="body1" component="li" gutterBottom>
                Engage in any unauthorized commercial activities or
                solicitations.
              </Typography>
            </ul>
          </Typography>

          <Typography variant="h5" component="li" mt={2} mb={1}>
            Privacy
            <Typography variant="body1" component="p" gutterBottom>
              Our collection and use of personal information are governed by our
              Privacy Policy. By using our Services, you consent to the
              collection, storage, and processing of your personal information
              as described in our Privacy Policy.
            </Typography>
          </Typography>

          <Typography variant="h5" component="li" mt={2} mb={1}>
            Third-Party Links and Content
            <Typography variant="body1" component="p" gutterBottom>
              Our Services may contain links to third-party websites or content.
              We do not endorse or control these third-party resources and are
              not responsible for their availability, accuracy, or content. Your
              use of third-party resources is at your own risk.
            </Typography>
          </Typography>

          <Typography variant="h5" component="li" mt={2} mb={1}>
            Disclaimer of Warranties
            <Typography variant="body1" component="p" gutterBottom>
              Cantaloupe News provides the Services on an "as is" and "as
              available" basis. We do not warrant or guarantee the accuracy,
              reliability, suitability, or availability of the Services or the
              content therein. You acknowledge and agree that your use of the
              Services is at your own risk.
            </Typography>
          </Typography>

          <Typography variant="h5" component="li" mt={2} mb={1}>
            Limitation of Liability
            <Typography variant="body1" component="p" gutterBottom>
              To the fullest extent permitted by law, Cantaloupe News and its
              affiliates, officers, directors, employees, or agents shall not be
              liable for any indirect, incidental, special, consequential, or
              punitive damages, including but not limited to loss of profits,
              data, or goodwill, arising from or relating to your use of the
              Services.
            </Typography>
          </Typography>

          <Typography variant="h5" component="li" mt={2} mb={1}>
            Indemnification
            <Typography variant="body1" component="p" gutterBottom>
              You agree to indemnify and hold Cantaloupe News and its
              affiliates, officers, directors, employees, or agents harmless
              from any claims, losses, damages, liabilities, costs, or expenses
              (including reasonable attorneys' fees) arising out of or relating
              to your use of the Services, your violation of these Terms, or
              your violation of any rights of another person or entity.
            </Typography>
          </Typography>

          <Typography variant="h5" component="li" mt={2} mb={1}>
            Governing Law and Jurisdiction
            <Typography variant="body1" component="p" gutterBottom>
              These Terms shall be governed by and construed in accordance with
              the laws of [Jurisdiction]. Any disputes arising under or in
              connection with these Terms shall be subject to the exclusive
              jurisdiction of the courts of [Jurisdiction].
            </Typography>
          </Typography>

          <Typography variant="h5" component="li" mt={2} mb={1}>
            Severability
            <Typography variant="body1" component="p" gutterBottom>
              If any provision of these Terms is found to be invalid, illegal,
              or unenforceable, the remaining provisions shall continue in full
              force and effect.
            </Typography>
          </Typography>

          <Typography variant="h5" component="li" mt={2} mb={1}>
            Changes to the Terms
            <Typography variant="body1" component="p" gutterBottom>
              Cantaloupe News reserves the right to modify or update these Terms
              at any time. Changes will be effective immediately upon posting.
              We encourage you to review these Terms periodically for any
              updates or modifications.
            </Typography>
          </Typography>

          <Typography variant="h5" component="li" mt={2} mb={1}>
            Contact Us
            <Typography variant="body1" component="p" gutterBottom>
              If you have any questions, concerns, or feedback regarding these
              Terms, please contact us at terms@cantaloupenews.com.
            </Typography>
          </Typography>
        </ol>
      </LayoutGrid>
    </>
  );
};

export default TermsOfService;
