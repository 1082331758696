import { Box, Typography } from "@mui/material";
import TwitterFeed from "components/TwitterFeed";
import { Helmet } from "react-helmet-async";
import { SideBar } from "./Story";
import MerchLinks from "components/MerchLinks";
import LayoutGrid from "layout/LayoutGrid";
import { useEffect } from "react";

const LeftSide = () => {
  return (
    <Box mt={3.5}>
      <TwitterFeed />
    </Box>
  );
};

const RightSide = () => {
  return (
    <Box mt={3.5}>
      <SideBar />
      <MerchLinks />
    </Box>
  );
};

const Error404 = () => {
  useEffect(() => {
    if (window.cntlp?.story) {
      window.cntlp = {};
    }
  });

  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
        <meta name="description" content="This page could not be found. Perhaps it had information on the Clintons? Try searching for something else." />
        <meta name="robots" content="noindex,nofollow" />
        <meta httpEquiv="Status" content="404 Not Found" />
      </Helmet>
      <LayoutGrid LeftSide={<LeftSide />} RightSide={<RightSide />}>
        <Typography variant="h3" component="h1" mt={2} color="primary">
          Page Not Found
        </Typography>
        <Typography variant="h6" component="p" mt={2} color="secondary">
          This page could not be found. Perhaps it had information on the
          Clintons?
          <br />
          Try searching for something else.
        </Typography>
      </LayoutGrid>
    </>
  );
};
export default Error404;
