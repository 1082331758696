import { Link, useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { getStoryById } from "graphql/queries";
import { GraphQLQuery } from "@aws-amplify/api";
import { useCallback, useContext, useEffect, useState } from "react";
import { GetStoryByIdQuery } from "API";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  Mail as MailIcon,
  Link as LinkIcon,
  ArrowBackIosNew as ArrowBackIcon,
  ArrowForwardIos as ArrowForwardIcon,
} from "@mui/icons-material";
import StoryBody from "components/StoryBody";
import { SiteContext } from "App";
import FormattedDate from "components/FormattedDate";
import { StoryImage, StoryItem } from "types/cantaloupe";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Timeline } from "react-twitter-widgets";
import LayoutGrid from "layout/LayoutGrid";
import MerchLinks from "components/MerchLinks";
import TwitterFeed from "components/TwitterFeed";
interface SideBarProps {
  storyId?: string;
  title?: string | undefined | null;
}

const StorySideLinks = ({
  storyId = "",
  title = "More Stories",
}: SideBarProps) => {
  //const { dateId, storyId } = useParams();
  const [prevStoryId, setPrevStoryId] = useState<string | null>(null);
  const [stories, setStories] = useState<(StoryItem | null)[] | null>(null);
  const [storiesLoading, setStoriesLoading] = useState(true);
  const { cdnDomain } = useContext(SiteContext);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const loadStories = useCallback(async () => {
    setStoriesLoading(true);
    const result: any = await API.graphql(
      graphqlOperation(
        `query ListStories($limit: Int) {
      listStories(limit: $limit) {
        stories {
          id
          title
          image {
            src
          }
        }
      }
    }`,
        {
          limit: 4,
        }
      )
    );

    setStories(
      result.data.listStories.stories
        .filter(
          (story: StoryItem, index: number, arr: Array<StoryItem>) =>
            story.id !== storyId?.replace(/\//g, "#") ||
            index === arr.length - 1
        )
        .filter((story: StoryItem, index: number) => index < 3)
    );
    setStoriesLoading(false);
  }, [storyId]);

  if (storyId !== prevStoryId) {
    loadStories();
    setPrevStoryId(storyId);
  }

  return (
    <>
      <Typography
        variant="h6"
        mt={2}
        mb={2}
        sx={{
          pt: { xs: 2, lg: 0 },
          borderTop: { xs: "1px solid", md: "none" },
          borderTopColor: { xs: "primary.main" },
        }}
      >
        {title}
      </Typography>

      {stories && !storiesLoading
        ? stories.map((story) => {
            const { id, title, image } = story ?? {};
            const imageSrc = `${cdnDomain}/${image?.[0]?.src}-${
              isMd || isSm ? "md" : "sm"
            }.jpg`;

            return (
              <Card key={id} sx={{ mb: 2 }}>
                <CardActionArea
                  component={Link}
                  to={`/story/${id?.replace(/#/g, "/")}`}
                  sx={{
                    display: { md: "block", sm: "flex" },
                    alignItems: "flex-start",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={imageSrc}
                    alt={title}
                    sx={{ width: { xs: "100%", sm: "225px", md: "100%" } }}
                  />
                  <CardContent sx={{ flexGrow: 1, flexBasis: 0 }}>
                    <Typography
                      variant={isMd || isSm ? "subtitle2" : "h6"}
                      sx={{ fontWeight: "bold" }}
                      component="div"
                    >
                      {title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })
        : new Array(3)
            .fill(null)
            .map((n, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={200}
                width="100%"
                animation="wave"
                sx={{ mb: 2, borderRadius: 1 }}
              />
            ))}
    </>
  );
};

interface StoryImageViewerProps {
  images: StoryImage[] | null;
  cdnDomain: string;
  title: string;
}

const LeftSide = () => {
  return (
    <Box mb={4} mt={2}>
      {/* <Timeline
        dataSource={{ sourceType: "profile", screenName: "CantaloupeNews" }}
        options={{
          chrome: "noborders",
          borderColor: "#eee",
          width: "100%",
          tweetLimit: "3",
          showReplies: "false",
        }}
      /> */}
      <TwitterFeed />
    </Box>
  );
};

const RightSide = ({ storyId = "" }: SideBarProps) => {
  return (
    <>
      <StorySideLinks storyId={storyId} />
      <MerchLinks />
    </>
  );
};

const StoryImageViewer = ({
  images,
  cdnDomain,
  title,
}: StoryImageViewerProps) => {
  const [curImage, setCurImage] = useState<number>(0);
  const [prevImages, setPrevImages] = useState<StoryImage[] | null>(null);

  if (!images) return <></>;

  if (images !== prevImages) {
    setPrevImages(images);
    setCurImage(0);
  }

  const buttonStyle = {
    position: "absolute",
    my: "auto",
    top: 0,
    bottom: 0,
    zIndex: 1,
  };

  const buttonClicked = (direction: "left" | "right") => {
    if (direction === "left") {
      if (curImage === 0) {
        setCurImage(images.length - 1);
      } else {
        setCurImage(curImage - 1);
      }
    }
    if (direction === "right") {
      if (curImage === images.length - 1) {
        setCurImage(0);
      } else {
        setCurImage(curImage + 1);
      }
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      {images.length > 1 && (
        <>
          <IconButton
            onClick={() => buttonClicked("left")}
            sx={{
              ...buttonStyle,
              left: 0,
            }}
          >
            <ArrowBackIcon sx={{ color: "white" }} />
          </IconButton>
          <IconButton
            onClick={() => buttonClicked("right")}
            sx={{
              ...buttonStyle,
              right: 0,
            }}
          >
            <ArrowForwardIcon sx={{ color: "white" }} />
          </IconButton>
        </>
      )}
      {images.map((image, key) => (
        <Card
          sx={{
            my: 2,
            position: "relative",
            display: key === curImage ? "block" : "none",
          }}
          key={key}
        >
          <CardMedia
            component="img"
            height="auto"
            image={`${cdnDomain}/${image.src}-lg.jpg`}
            alt={image.caption?.length ? image.caption : title ?? ""}
          />
          <CardContent
            sx={{
              backgroundColor: "rgba(0,0,0,0.85)",
              color: "#ddd",
              p: 1,
              px: 4,
            }}
          >
            <Typography variant="caption">
              {image.caption?.length ? image.caption : title} (
              {image.credit?.length ? image.credit : "Cantaloupe News"})
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

const Story = () => {
  const { dateId, storyId } = useParams();
  const [prevID, setPrevID] = useState<string | null>(null);
  const [title, setTitle] = useState(window.cntlp?.story?.title);
  const [body, setBody] = useState<any>(window.cntlp?.story?.body);
  const [prevPathName, setPrevPathName] = useState<string | null>(null);
  const [date, setDate] = useState<string | undefined | null>(
    window.cntlp?.story?.date
  );
  const [isError, setIsError] = useState(window.cntlp?.story?.isError);

  const [images, setImages] = useState<StoryImage[] | null>(
    window.cntlp?.story?.image ?? []
  );
  const [author, setAuthor] = useState<string | undefined | null>(
    window.cntlp?.story?.author
  );
  const { cdnDomain } = useContext(SiteContext);
  const { pathname } = useLocation();

  const fetchStory = useCallback(async () => {
    const result = await API.graphql<GraphQLQuery<GetStoryByIdQuery>>({
      query: getStoryById,
      variables: { id: `${dateId}#${storyId}` },
    });

    if (!result?.data?.getStoryById) {
      setIsError(true);
      setTitle("Page Not Found");
      setBody(
        "This page could not be found. Perhaps it had information on the Clintons? Try searching for something else."
      );
      setDate("");
      setImages([]);
      setAuthor("");

      return;
    }
    setIsError(false);
    setTitle(result?.data?.getStoryById?.title ?? "");
    setBody(JSON.parse(result?.data?.getStoryById?.body ?? '{"content": []}'));
    const imageList = (result?.data?.getStoryById?.image ?? []).map((img) => ({
      src: img?.src ?? "",
      caption: img?.caption ?? "",
      alt: img?.caption ?? "",
    }));
    setImages(imageList);
    setAuthor(result?.data?.getStoryById?.author ?? "");
    setDate(result?.data?.getStoryById?.date ?? "");
  }, [dateId, storyId]);

  if (pathname !== prevPathName) {
    if (prevPathName) {
      setTitle(null);
      setBody(null);
      setDate(null);
      setImages([]);
      setAuthor(null);
    }
    setPrevPathName(pathname);
  }

  useEffect(() => {
    if (window.cntlp?.story) {
      window.cntlp = {};
    }
  });

  if (`${dateId}/${storyId}` !== prevID) {
    fetchStory();
    setPrevID(`${dateId}/${storyId}`);
  }

  return (
    <>
      <Helmet>
        <title>
          {title
            ? title + " | Cantaloupe News"
            : "Loading... | Cantaloupe News"}
        </title>
      </Helmet>

      <LayoutGrid
        LeftSide={<LeftSide />}
        RightSide={<RightSide storyId={`${dateId}/${storyId}`} />}
      >
        {/* <Box maxWidth="lg"> */}
        {title ? (
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            color={isError ? "primary" : ""}
          >
            {title}
          </Typography>
        ) : (
          <Skeleton variant="text" sx={{ fontSize: "4rem" }} />
        )}

        {author ? (
          <Typography variant="body2">By {author}</Typography>
        ) : author === "" ? (
          ""
        ) : (
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
        )}

        {date ? (
          <Typography sx={{ mt: 0.5 }} variant="body2">
            Published {<FormattedDate dateString={date} />}
          </Typography>
        ) : date === "" ? (
          ""
        ) : (
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
        )}

        {title && dateId && storyId && !isError ? (
          <Box mb={0}>
            <Tooltip title="Share on Twitter" arrow>
              <IconButton
                onClick={() =>
                  window.open(
                    `https://twitter.com/intent/tweet?text=${title} https://${window.location.hostname}/story/${dateId}/${storyId} via @CantaloupeNews`,
                    "_blank",
                    "popup=yes,width=500,height=600,menubar=no,toolbar=no,status=no,scrollbars=yes"
                  )
                }
              >
                <TwitterIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share on Facebook" arrow>
              <IconButton
                onClick={() =>
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=https://cantaloupenews.com/story/${dateId}/${storyId}`,
                    "_blank",
                    "popup=yes,width=500,height=600,menubar=no,toolbar=no,status=no,scrollbars=yes"
                  )
                }
              >
                <FacebookIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share via Email" arrow>
              <IconButton
                href={`mailto:?subject=${title}%20-%20Cantaloupe%20News&body=https://${window.location.hostname}/story/${dateId}/${storyId}`}
              >
                <MailIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy Link" arrow>
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(
                    `https://${window.location.hostname}/story/${dateId}/${storyId}`
                  )
                }
              >
                <LinkIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <></>
        )}
        {/* </Box> */}
        {/* </LayoutGrid>
      <LayoutGrid
        LeftSide={<LeftSide />}
        RightSide={<SideBar storyId={`${dateId}/${storyId}`} />}
      > */}
        <Box
          maxWidth="md"
          display={{ lg: "flex" }}
          flexDirection="row"
          justifyContent="space-between"
          gap={6}
        >
          <Box flexGrow={24} flexBasis={0}>
            <StoryImageViewer
              images={images}
              cdnDomain={cdnDomain}
              title={title ?? ""}
            />

            {body ? (
              isError ? (
                <Typography
                  variant="body1"
                  sx={{ my: 6, fontSize: "1.5rem" }}
                  component="p"
                  color="secondary"
                >
                  {body}
                </Typography>
              ) : (
                <StoryBody body={body} />
              )
            ) : (
              new Array(15)
                .fill(null)
                .map((i, n) => (
                  <Skeleton
                    key={n}
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1rem" }}
                  />
                ))
            )}
          </Box>
        </Box>
      </LayoutGrid>
    </>
  );
};
export default Story;
export { StorySideLinks as SideBar };
