interface FormattedDateProps {
    dateString?: string | undefined | null;
}
const isDST = (date: Date) => {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return date.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};
const FormattedDate = ({dateString}: FormattedDateProps) => {
  if(!dateString)
    return null;
  
  const date = new Date(dateString);
  
  const timePart = new Intl.DateTimeFormat("en-US", {timeStyle: "short", timeZone: "America/New_York"}).format(date);
  const datePart = new Intl.DateTimeFormat("en-US", {dateStyle: "full", timeZone: "America/New_York"}).format(date);
  const timeZone =  isDST(date) ? "(EDT)" : "(EST)";
  
  return <>{timePart} {timeZone}, {datePart}</>
};
export default FormattedDate;