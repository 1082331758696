import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  LinearProgress,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { SideBar } from "./Story";
import TwitterFeed from "components/TwitterFeed";
import MerchLinks from "components/MerchLinks";
import LayoutGrid from "layout/LayoutGrid";
import { useState } from "react";
import { API } from "aws-amplify";
import {
  GraphQLQuery,
  graphqlOperation,
  GraphQLResult,
} from "@aws-amplify/api";

import {
  submitContactFormMessage,
  sendContactFormMessage,
} from "graphql/mutations";
import {
  ContactFormChallenge,
  ContactFormMessage,
  SubmitContactFormMessageMutation,
  SendContactFormMessageMutation,
  ContactFormChallengeResponse,
  ContactFormChallengeResult,
} from "API";
import { set } from "remirror";

const LeftSide = () => {
  return (
    <Box mt={3.5}>
      <TwitterFeed />
    </Box>
  );
};

const RightSide = () => {
  return (
    <Box mt={3.5}>
      <SideBar />
      <MerchLinks />
    </Box>
  );
};

enum Topics {
  "Feedback" = "Feedback",
  "Contribution" = "Contribution",
  "Advertising" = "Advertising",
  "Other" = "Other",
}

const generateHash = async (s: string) => {
  return Array.from(
    new Uint8Array(
      await crypto.subtle.digest("SHA-256", new TextEncoder().encode(s))
    )
  )
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
};

const ContactUs = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [topic, setTopic] = useState("");
  const [feedbackReason, setFeedbackReason] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const [details, setDetails] = useState("");
  const [emailSending, setEmailSending] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [alert, setAlert] = useState<{
    severity: AlertColor;
    text: string;
    children?: JSX.Element | null | undefined;
    timeout?: number | null | undefined;
  } | null>(null);

  const validateForm = (fieldName?: string) => {
    if (!fieldName) setErrors([]);
    let localErrors = [];

    if ((!fieldName || fieldName === "fullName") && fullName.trim().length < 3)
      localErrors.push("fullName");

    if (
      (!fieldName || fieldName === "email") &&
      email.match(/.+@.+\..+/) === null
    )
      localErrors.push("email");

    if (!fieldName && topic === "") localErrors.push("topic");

    if (!fieldName && topic === "feedback" && feedbackReason === "")
      localErrors.push("feedbackReason");

    if ((!fieldName || fieldName === "details") && details.trim().length < 10)
      localErrors.push("details");

    if (localErrors.length > 0) {
      setErrors(localErrors);
      return false;
    }

    return true;
  };
  const resetForm = () => {
    setFullName("");
    setEmail("");
    setTopic("");
    setFeedbackReason("");
    setDetails("");
    setErrors([]);
  };
  const onSubmitClick = async () => {
    setAlert(null);
    if (!validateForm()) return;

    setEmailSending(true);

    const submitMessageInput: ContactFormMessage = {
      fullName,
      email,
      topic,
      feedbackReason,
      details,
    };
    const submitResult: GraphQLResult<SubmitContactFormMessageMutation> =
      await API.graphql<GraphQLQuery<SubmitContactFormMessageMutation>>({
        query: submitContactFormMessage,
        variables: {
          message: submitMessageInput,
        },
      });

    const difficulty = submitResult.data?.submitContactFormMessage?.difficulty;
    const challengeHash = submitResult.data?.submitContactFormMessage?.hash;

    if (
      submitResult.data?.submitContactFormMessage?.status === "error" ||
      !difficulty ||
      !challengeHash
    ) {
      setEmailSending(false);
      setAlert({
        severity: "error",
        text: "Your message could not be submitted. Please try again later.",
        timeout: 10000,
      });
      return;
    }
    let hashResult = "";
    let hashMessage = "";
    let nonce = 0;
    while (!hashResult.slice(-difficulty).match(`[012]{${difficulty}}$`)) {
      hashMessage = JSON.stringify({
        hash: challengeHash,
        nonce: ++nonce,
      });
      hashResult = await generateHash(hashMessage);
    }

    const sendMessageInput: ContactFormChallengeResponse = {
      hash: challengeHash,
      hashMessage,
    };
    const sendResult: GraphQLResult<SendContactFormMessageMutation> =
      await API.graphql<GraphQLQuery<SendContactFormMessageMutation>>({
        query: sendContactFormMessage,
        variables: { challengeResponse: sendMessageInput },
      });
    if (sendResult.data?.sendContactFormMessage?.status === "success") {
      resetForm();
      setEmailSending(false);
      setEmailSent(true);
      setAlert({
        severity: "success",
        text: "Your message has been sent!",
        timeout: 60000,
      });
      return;
    } else {
      setEmailSending(false);
      setAlert({
        severity: "error",
        text: "Your message could not be sent. Please try again later.",
        timeout: 10000,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Cantaloupe News</title>
      </Helmet>
      <LayoutGrid LeftSide={<LeftSide />} RightSide={<RightSide />}>
        <Typography variant="h3" component="h1" mt={2}>
          Contact Us
        </Typography>
        <Typography variant="body1" component="p" mt={2}>
          If you have something to tell us, we'd love to hear from you - unless
          you just came to say how offended you are by comedy, in which case we
          kindly request that you write your request using cow dung and submit
          it via your own digestive system (please chew it well).
        </Typography>
        {emailSent || (
          <Box sx={{ mt: 2 }} display="flex" flexDirection="column" gap={2}>
            <TextField
              name="name"
              label="Full name"
              variant="outlined"
              fullWidth
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              error={errors.includes("fullName")}
              helperText={
                errors.includes("fullName") && "Please provide your full name."
              }
              onBlur={() => validateForm("fullName")}
            />
            <TextField
              name="email"
              label="Email address"
              type="email"
              variant="outlined"
              error={errors.includes("email")}
              helperText={
                errors.includes("email") &&
                "Please provide a valid email address."
              }
              onBlur={() => validateForm("email")}
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormControl fullWidth>
              <FormLabel error={errors.includes("topic")}>Topic:</FormLabel>
              <RadioGroup
                name="topic"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
              >
                {errors.includes("topic") && (
                  <FormHelperText error={true}>
                    Please select a topic.
                  </FormHelperText>
                )}
                <FormControlLabel
                  value="feedback"
                  control={<Radio />}
                  label="Feedback"
                />
                {topic === "feedback" && (
                  <RadioGroup
                    name="topic"
                    sx={{ ml: 4 }}
                    value={feedbackReason}
                    onChange={(e) => setFeedbackReason(e.target.value)}
                  >
                    <FormLabel>Please be specific:</FormLabel>
                    <FormControlLabel
                      value="offended"
                      control={<Radio />}
                      label="I'm offended or disagree with something on this site."
                    />
                    <FormControlLabel
                      value="criticism"
                      control={<Radio />}
                      label="Constructive criticism."
                    />
                  </RadioGroup>
                )}
                <FormControlLabel
                  value="Contribution"
                  control={<Radio />}
                  label="Contribution"
                />
                <FormControlLabel
                  value="Advertising"
                  control={<Radio />}
                  label="Advertising"
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              multiline
              name="details"
              label="Details"
              helperText="Please provide the full details of your request, including any additional contact information."
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              error={errors.includes("details")}
              onBlur={() => validateForm("details")}
            />
            <Box display="flex" justifyContent="flex-end" gap={1}>
              <Button
                color="secondary"
                variant="outlined"
                sx={{ mt: 2 }}
                onClick={resetForm}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={onSubmitClick}
                disabled={
                  emailSending ||
                  topic === "" ||
                  (topic === "feedback" && feedbackReason !== "criticism")
                }
              >
                Submit
              </Button>
            </Box>
          </Box>
        )}
      </LayoutGrid>
      <Dialog open={feedbackReason === "offended"}>
        <DialogTitle color="white" bgcolor="primary.main">
          Don't be such a baby
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText>
            We're sorry to hear that your emotional maturity is so low that you
            feel the need to cry to us about how satirical content makes you
            feel worthless. We recommend complaining to the public education
            system, as they are most likely the ones who failed you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFeedbackReason("")}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={emailSending}>
        <DialogContent>
          <DialogContentText>
            Sending message...
          </DialogContentText>
          <LinearProgress />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={alert !== null}
        autoHideDuration={alert?.timeout ?? 3000}
        onClose={(event, reason) => {
          if (reason === "timeout") setAlert(null);
        }}
      >
        <Alert
          variant="filled"
          severity={alert?.severity}
          sx={{ width: "100%" }}
          onClose={
            !alert?.timeout
              ? undefined
              : () => {
                  setAlert(null);
                }
          }
        >
          {alert?.text ?? ""}
          {alert?.children}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactUs;
