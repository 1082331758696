import {
  Container,
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  createTheme,
  CssBaseline,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, Outlet, useLocation } from "react-router-dom";
import UserLogin from "../components/UserLogin";
import MenuIcon from "@mui/icons-material/Menu";

import { ReactComponent as MainLogo } from "../images/main-logo.svg";
import { ReactComponent as HeaderLogo } from "../images/website-header-logo.svg";
import { Auth } from "aws-amplify";
import { SiteContext } from "App";
import { AwsRum } from "aws-rum-web";

const topLinks = [
  //{ title: "News", href: "/news" },
  {
    title: "Store",
    href: "https://cantaloupenews.creator-spring.com/",
    external: true,
  },
  { title: "About", href: "/about" },
  { title: "Contact Us", href: "/contact-us" },
];

const MainLayout = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [intervalChecker, setIntervalChecker] = useState<
    number | NodeJS.Timer | null
  >(null);
  const [prevPathName, setPrevPathName] = useState<string>("");
  const { pathname } = useLocation();
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  //const {awsRum}:{awsRum: {instance:AwsRum}} = useContext(SiteContext);

  if (pathname !== prevPathName) {
    window.scrollTo(0, 0);
    setPrevPathName(pathname);
  }

  useEffect(() => {
    if (window.awsRum) {
      window.awsRum.recordPageView(pathname);
    }
  }, [pathname]);

  // Refresh credentials once a minute, if expired
  useEffect(() => {
    if (intervalChecker !== null) {
      clearInterval(intervalChecker);
    } else {
      setIntervalChecker(
        setInterval(() => {
          Auth.currentCredentials();
        }, 60 * 1000)
      );
    }
  }, [intervalChecker]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div className="App">
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>Cantaloupe News</title>
        <meta
          name="description"
          content="Your Trusted Source for Political Satire | Like an onion, only sweeter™"
        />
      </Helmet>
      <CssBaseline />
      {/* <Container> */}
      <AppBar position="fixed">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexFlow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {topLinks.map((link) => (
                  <MenuItem
                    key={link.title}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={link.href}
                    target={link.external ? "_blank" : undefined}
                  >
                    <Typography textAlign="center">{link.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "flex" },
                alignItems: "center",
              }}
            >
              <Link to="/">
                <HeaderLogo style={{ width: 192, height: 48 }} />
              </Link>
              <Box
                sx={{ display: { xs: "none", md: "block" } }}
                flexGrow={1}
              ></Box>
              <Box sx={{ display: { xs: "none", md: "flex" }, mr: 4 }}>
                {topLinks.map((link) => (
                  <Link
                    to={link.href}
                    key={link.title}
                    target={link.external ? "_blank" : undefined}
                  >
                    <Typography
                      variant="h6"
                      noWrap
                      style={{ color: "white", marginLeft: "2rem" }}
                    >
                      {link.title}
                    </Typography>
                  </Link>
                ))}
              </Box>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <UserLogin />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar sx={{ mb: 2 }} />{" "}
      {/* Adds offset so content starts after appbar */}
      
        <Box component="main" minHeight="70vh">
          <Outlet />
        </Box>

      
      {/* </Container> */}
      {!pathname.startsWith("/admin/") && /*mdAndUp &&*/ (
        <Box
          component="footer"
          boxSizing="border-box"
          sx={{ mt: 4, bgcolor: "black", color: "primary", p: 6 }}
        >
          <Container
            maxWidth="lg"
            sx={{ display: { md: "flex" }, justifyContent: "space-between" }}
          >
            <ul style={{ paddingLeft: 0 }}>
              <li>
                <Link to="/privacy-policy">
                  <Typography variant="body2" color="primary">
                    Privacy Policy
                  </Typography>
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service">
                  <Typography variant="body2" color="primary">
                    Terms of Service
                  </Typography>
                </Link>
              </li>
            </ul>

            <Link to="/">
              <MainLogo
                width={mdAndUp ? 384 : "80%"}
                height={mdAndUp ? 96 : null}
              />
            </Link>
          </Container>
        </Box>
      )}
    </div>
  );
};

export default MainLayout;
