import { Box, Typography } from "@mui/material";
import TwitterFeed from "components/TwitterFeed";
import { Helmet } from "react-helmet-async";
import { SideBar } from "./Story";
import MerchLinks from "components/MerchLinks";
import LayoutGrid from "layout/LayoutGrid";

const LeftSide = () => {
  return (
    <Box mt={3.5}>
      <TwitterFeed />
    </Box>
  );
};

const RightSide = () => {
  return (
    <Box mt={3.5}>
      <SideBar />
      <MerchLinks />
    </Box>
  );
};

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Cantaloupe News</title>
      </Helmet>
      <LayoutGrid LeftSide={<LeftSide />} RightSide={<RightSide />}>
        <Typography variant="h3" component="h1" mt={2}>
          Privacy Policy
        </Typography>
        <Typography variant="body2" component="p" gutterBottom ml={1}>
          Effective date: <time dateTime="2023-06-28">June 28, 2023</time>
        </Typography>
        <Typography variant="body1" component="p" gutterBottom mt={2}>
          At Cantaloupe News, we respect your privacy and are committed to
          protecting your personal information. This Privacy Policy outlines how
          we collect, use, and safeguard the information you provide when using
          our website (cantaloupenews.com) and interacting with our services. By
          accessing or using our website, you consent to the practices described
          in this policy.
        </Typography>

        <ol>
          <Typography variant="h5" component="li" mt={2} mb={1}>
            Information We Collect
            <Typography variant="body1" component="p" gutterBottom>
              1.1 Personal Information: We may collect personal information that
              you voluntarily provide to us, such as your name, email address,
              and any other information you choose to provide when registering
              for an account or leaving comments on our articles.
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              1.2 Automatically Collected Information: When you visit our
              website, we may automatically collect certain information about
              your device, browsing actions, and patterns. This may include your
              IP address, browser type, operating system, referring URLs, and
              other information about how you interact with our website.
            </Typography>
          </Typography>

          <Typography variant="h5" component="li" mt={2} mb={1}>
            Use of Information
            <Typography variant="body1" component="p" gutterBottom>
              2.1 Personal Information: We may use the personal information you
              provide to:
            </Typography>
            <ul>
              <Typography variant="body1" component="li" gutterBottom>
                Facilitate your account registration and manage your user
                profile.
              </Typography>
              <Typography variant="body1" component="li" gutterBottom>
                Respond to your inquiries or comments.
              </Typography>
              <Typography variant="body1" component="li" gutterBottom>
                Send you promotional materials, newsletters, or other
                communications, if you have opted to receive them.
              </Typography>
            </ul>
            <Typography variant="body1" component="p" gutterBottom>
              2.2 Automatically Collected Information: The information we
              automatically collect is used for statistical purposes, website
              improvement, and to enhance your experience while using our
              services. This information helps us understand user preferences,
              troubleshoot technical issues, and tailor content to your
              interests.
            </Typography>
          </Typography>
          <Typography variant="h5" component="li" mt={2} mb={1}>
            Cookies and Tracking Technologies
            <Typography variant="body1" component="p" gutterBottom>
              We may use cookies, web beacons, and other tracking technologies
              to collect and store certain information automatically. These
              technologies help us analyze trends, administer the website, track
              users' movements around the site, and gather demographic
              information about our user base. You can control the use of
              cookies through your browser settings.
            </Typography>
          </Typography>
          <Typography variant="h5" component="li" mt={2} mb={1}>
            Disclosure of Information
            <Typography variant="body1" component="p" gutterBottom>
              We may disclose your personal information in the following
              circumstances:
            </Typography>
            <ul>
              <Typography variant="body1" component="li" gutterBottom>
                To trusted third-party service providers who assist us in
                operating our website and conducting our business, as long as
                they agree to keep this information confidential.
              </Typography>
              <Typography variant="body1" component="li" gutterBottom>
                In response to a legal request or when we believe disclosure is
                necessary to protect our rights, comply with a judicial
                proceeding, court order, or other legal processes.
              </Typography>
              <Typography variant="body1" component="li" gutterBottom>
                In the event of a merger, acquisition, or sale of all or a
                portion of our assets, we may transfer your information to the
                acquiring entity.
              </Typography>
            </ul>
          </Typography>
          <Typography variant="h5" component="li" mt={2} mb={1}>
            Data Security
            <Typography variant="body1" component="p" gutterBottom>
              We implement reasonable security measures to protect your personal
              information from unauthorized access, loss, misuse, alteration, or
              destruction. However, please be aware that no method of
              transmission over the internet or electronic storage is completely
              secure, and we cannot guarantee absolute security.
            </Typography>
          </Typography>
          <Typography variant="h5" component="li" mt={2} mb={1}>
            Children's Privacy
            <Typography variant="body1" component="p" gutterBottom>
              Our website is not intended for use by individuals under the age
              of 13. We do not knowingly collect personal information from
              children under 13 years of age. If you believe that we have
              inadvertently collected information from a child, please contact
              us, and we will promptly remove the information from our records.
            </Typography>
          </Typography>
          <Typography variant="h5" component="li" mt={2} mb={1}>
            Links to Third-Party Websites
            <Typography variant="body1" component="p" gutterBottom>
              Our website may contain links to third-party websites or services.
              We are not responsible for the privacy practices or content of
              these third parties. We encourage you to review the privacy
              policies of those websites before providing any personal
              information.
            </Typography>
          </Typography>
          <Typography variant="h5" component="li" mt={2} mb={1}>
            Changes to the Privacy Policy
            <Typography variant="body1" component="p" gutterBottom>
              We reserve the right to modify or update this Privacy Policy at
              any time. We will indicate the date of the latest revision at the
              beginning of this policy. We encourage you to review this Privacy
              Policy periodically for any changes.
            </Typography>
          </Typography>
          <Typography variant="h5" component="li" mt={2} mb={1}>
            Contact Us
            <Typography variant="body1" component="p" gutterBottom>
              If you have any questions, concerns, or requests regarding this
              Privacy Policy, please contact us at privacy@cantaloupenews.com.
            </Typography>
          </Typography>
        </ol>
      </LayoutGrid>
    </>
  );
};
export default PrivacyPolicy;
